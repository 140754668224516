.mainPage {
    padding: 20px;
}

.row {
    margin-bottom: 20px;
}

.col-6 {
    background: white;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 10px;
}

.posts-list {
    margin-top: 20px;
}

.post-box {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}

.events-list {
    margin-top: 20px;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    width: 250px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 20px;
    position: absolute;
    top: 70px;
    left: 0;
    z-index: 500;
}

.events-list input {
    margin-right: 10px;
}
