#profile span:hover {
    color: white;
    cursor: pointer;
}

body {
    font-family: Nunito, sans-serif;
    line-height: 1.6;
    background-image: url("img/flowers.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    margin: 0;
    padding: 0;
}

header {
    color: black;
    text-align: center;
}

main {
    padding: 20px;
}

.mainPage {
    padding-left: 200px;
}
.message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.sender {
    font-weight: bold;
}

.timestamp {
    color: #888;
    font-size: 0.9em;
}

footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
}

#icons {
    display: inline-block;
}

#login {
    text-align: center;
    padding: 100px;
    background-color: white;
    margin-top: 100px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

#settings {
    border: 1px solid gray;
    background-color: white;
    margin-top: 50px;
    text-align: center;
}

#profile-picture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.posts-list {
    /* border: 1px solid gray; */
    background-color: white;
    text-align: center;
    max-width: 600px;
    margin-top: 20px;
    margin: 20px auto;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.post-box {
    border: 1px solid #ccc;
    padding: 15px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    padding: 30px;
}

.post-box p {
    margin: 0;
    font-size: 14px;
    color: #333;
}

.post-actions {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
}

.post-actions button {
    margin-right: 10px;
    padding: 5px 10px;
    border: none;
    background-color: #b2967d;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.post-actions button:hover {
    background-color: #b2967d;
}

.post-comments {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #ddd;
}

.modal {
    display: block; /* Show the modal */
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal-backdrop.show {
    opacity: 1;
    visibility: visible;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    /* transform: translateY(-30px); */
    /* opacity: 0; */
    /* transition: transform 0.3s ease, opacity 0.3s ease; */
    margin: 15% auto;
    text-align: center;
}

.modal-backdrop.show .modal-content {
    transform: translateY(0);
    opacity: 1;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.modal-dialog-inbox {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Full viewport height */
}

.modal-content-inbox {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    text-align: center;
}

.modal-body-inbox {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn-close {
    margin-top: 10px;
    background-color: #f44336; /* Red color for close button */
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
}

.inbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh; /* Ensure the full height is used */
    padding-top: 20px; /* Adds some spacing from the top */
    position: relative;
}

/* .btn-primary {
    position: absolute;
    top: 20px;
    right: 20px; Aligns the button in the top-right corner
} */

.inbox-container {
    display: flex;
    height: 100vh;
}

.message-list {
    width: 30%; /* Adjust the width of the message list */
    background-color: white; /* White background */
    padding: 20px;
    border-right: 1px solid #ddd; /* Optional border for separation */
    overflow-y: auto; /* Allow scrolling if there are many messages */
}

.message-thread {
    flex-grow: 1; /* Take up the remaining space */
    padding: 20px;
    background-color: #f8f9fa; /* Light background for the message thread */
}

.messageBox {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: background-color 0.2s ease;
}

.messageBox:hover {
    background-color: #f0f0f0;
}

.messages {
    margin-top: 20px;
}

.inbox-container {
    display: flex;
    height: 100vh;
}

.message-list {
    width: 30%;
    background-color: white;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto;
    position: relative;
}

.compose-icon-btn:hover {
    background-color: #b2967d;
}
